<template>
  <div class="e-module e-module--limited">
    <main class="e-chat">
      <header class="e-chat__header">
        <div class="e-chat__title">
          {{ chatTitle }}
        </div>
        <IconTrash
          class="e-chat-delete"
          @click="deleteUserChat(propId)"
        />
      </header>

      <div class="e-chat__dialog">
        <VuePerfectScrollbar
          ref="scrollContainer"
          class="e-module"
        >
          <div class="e-chat__content-inner">
            <chat-item
              v-for="(msg, index) in messages"
              :key="index"
              :type="msg.type"
              :messages="msg.messages"
              :read-only="true"
            />
          </div>
        </VuePerfectScrollbar>
      </div>
    </main>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapGetters } from 'vuex'
import ChatItem from 'molecules/ChatItem/ChatItem'
import IconTrash from 'assets/icons/trashcan.svg'
import api from 'api'

export default {
  /** ========================================== */
  /*     COMPONENTS                              */
  /** ========================================== */
  components: {
    ChatItem,
    VuePerfectScrollbar,
    IconTrash
  },

  props: {
    propMessages: {
      type: Array,
      default: () => [],
      required: false
    },
    propId: {
      type: String,
      default: null,
      required: false
    },
    opponent: {
      type: String,
      default: null,
      required: false
    }
  },
  /** ========================================== */
  /*     DATA                                    */
  /** ========================================== */
  data: () => ({
    chatTextMessage: ''
  }),

  /** ========================================== */
  /*     COMPUTED                                */
  /** ========================================== */
  computed: {
    ...mapGetters(['userInfo']),

    chatTitle () {
      let title = `${this.$t('chat.title')} ${this.opponent}`

      return title
    },

    messages () {
      if (this.userInfo === undefined) {
        return []
      }
      let messages = this.propMessages
      if (messages == null) {
        return
      }
      if (this.hasChat && this.currentUserChat.messages.length > 0) {
        messages = this.currentUserChat.messages
      }

      const output = []
      messages.forEach((msg, i) => {
        if (i > 0 && output[output.length - 1].userId === msg.userId) {
          output[output.length - 1].messages.push({ text: msg.body, id: msg.id, deleted: msg.isDeleted, createdAt: msg.createdAt })
        } else {
          output.push({
            type: msg.userId !== this.userInfo.id ? 'opponent' : 'user',
            userId: msg.userId,
            messages: [{ text: msg.body, id: msg.id, deleted: msg.isDeleted, createdAt: msg.createdAt }]
          })
        }
      })

      return output
    }
  },

  /** ========================================== */
  /*    UPDATED                                 */
  /** ========================================== */
  updated () {
    // this.$nextTick(() => {
    //   this.scrollToBottom()
    // })
  },

  /** ========================================== */
  /*     METHODS                                 */
  /** ========================================== */
  methods: {

    scrollToBottom () {
      if (this.$refs.scrollContainer) {
        this.$refs.scrollContainer.$el.scrollTop = this.$refs.scrollContainer.$el.scrollHeight
      }
    },

    deleteUserChat (id) {
      this.$confirm(this.$t('chat.userChat.delete'), this.$t('chat.userChat.delete'), {
        confirmButtonText: this.$t('system.confirm'),
        cancelButtonText: this.$t('system.cancel'),
        type: 'warning',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true

            try {
              await api.call('deleteUserChat', id)
              done()
            } catch (error) {
              this.$error(error)
              if (error.messages) {
                this.$message.error(error.messages[0])
              }
            } finally {
              instance.confirmButtonLoading = false
            }
          } else {
            done()
          }
        }
      }).then(() => {
        this.$message({
          type: 'success',
          message: this.$t('chat.userChat.deleteSuccess')
        })
        this.$router.push({
          name: 'chatDialogs'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '_assets/ReadChat';
</style>
